@import './../variables.scss';

.header {
    color: $White;
    width: 100%;
    .headerContainer {
        display: flex;
        flex-direction: row;
        .logo {
            flex: 1;
            align-self: center;
            text-align: center;
            .logoImg {
                height: 60px;
                width: "auto";
            }
        }
        .menuContainer {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            a {
                text-decoration: none;
              }
            .item {
                font-size: $xSmall;
                margin-right: 12px;
                padding: 8px 12px;
                border-radius: 4px;
                color: var(--secondary-foreground, $Grey);
                &.active {
                    cursor: pointer;
                    background: $Gradient;
                    color: $White;
                    &:hover {
                        color: $White;
                        text-decoration: underline;
                    }
                }
                &:hover {
                    cursor: pointer;
                    color: var(--secondary-accent, $Purple);
                }
            }
        }
        .actionContainer {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .switch {
                margin-right: 18px;
                outline: none;
            }
            .item {
                font-size: $xSmall;
                margin-right: 12px;
                padding: 8px 12px;
                border-radius: 4px;
                color: var(--secondary-foreground, $Grey);
                &:hover {
                    cursor: pointer;
                    color: var(--secondary-accent, $Purple);
                }
            }
        }
    }
    .toggleButton {
        display: none;
    }
    .customButton {
        background: transparent;
        font-size: $xSmall;
        font-weight: 400;
        color: $Grey;
        border: 1px solid $DarkGrey;
        &:hover {
            background: $Purple;
            color: $White;
            cursor: pointer;
            border-color: transparent;
        }
    }
    a {
        text-decoration: none;
    }
}

@media (max-width: 768px) {
    .header {
        .headerContainer {
            .logo {
                flex: 1;
                align-self: left;
                text-align: left;
                .logoImg {
                    height: 36px;
                    width: "auto";
                }
            }
            .menuContainer,
            .actionContainer {
                display: none;
            }
            .toggleButton {
                display: block;
            }
        }
    }
  }