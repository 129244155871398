//Colors
$White: #FFFFFF;
$Black: #000000;
//$Gradient: radial-gradient(126.62% 142.09% at 3.25% 24.29%, #E339FF 0%, #FF006B 60.94%, #D92A5E 99.97%, #FF68D5 99.98%, #FC0002 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
$Gradient: linear-gradient(90deg, #F5CD80 5.23%, #8FF6E2 36.98%, #938AFD 67.71%, #F243AC 100%);
$GradientLight: radial-gradient(101.75% 114.08% at 3.25% 24.29%, rgba(227, 57, 255, 0.2) 0%, rgba(255, 0, 107, 0.2) 60.94%, rgba(252, 0, 2, 0.2) 99.96%, #D92A5E 99.97%, #FF68D5 99.98%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
$BgGradient: radial-gradient(114.81% 61.17% at 50.00% 44.84%, #14142D 0%, #0B0711 99.99%, #090A15 100%);
$BgGradinet2: radial-gradient(24477.02% 150.18% at 2.45% 24.23%, #2F0F58 0%, #52093D 100%);
$BgBlue: #16162A;
$BgPurple: #2A1C36;
$Grey: #8e92bf;
$DarkGrey: #37394b;
$ExtraDarkGrey: #0b0711;
$Purple: #e11470;

//Font Sizes
$xxxSmall: 0.5rem;
$xxSmall: 0.75rem;
$xSmall: 0.875rem;
$small: 1rem;
$medium: 1.125rem;
$large: 1.25rem;
$xLarge: 1.5rem;
$xxLarge: 1.875rem;
$xxxLarge: 2.25rem;
