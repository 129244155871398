.app {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  // background-color: #000000 !important;
}

.appLight {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background-color: #ffffff !important;
}

@media (max-width: 768px) {
    .app {
      padding: 1rem;
    }
}