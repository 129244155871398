@import './../variables.scss';

.chat {
    width: 100%;
    .chatContainer {
        .messagesOuterContainer {
            margin-top: 48px;
            background: linear-gradient(90deg, #F5CD80 5.23%, #8FF6E2 36.98%, #938AFD 67.71%, #F243AC 100%);
            padding: 3px;
            border-radius: 8px;
            // &.animate {
            //     background: linear-gradient(90deg, #F5CD80 5.23%, #8FF6E2 36.98%, #938AFD 67.71%, #F243AC 100%);
            //     animation: gradient 5s linear infinite;
            // }
            // @keyframes gradient {
            //     0% {
            //         background: linear-gradient(90deg, #F5CD80 0%, #8FF6E2 25%, #938AFD 50%, #F243AC 75%, #F5CD80 100%);
            //     }
            //     25% {
            //         background: linear-gradient(90deg, #F243AC 0%, #F5CD80 25%, #8FF6E2 50%, #938AFD 75%, #F243AC 100%);
            //     }
            //     50% {
            //         background: linear-gradient(90deg, #938AFD 0%, #F243AC 25%, #F5CD80 50%, #8FF6E2 75%, #938AFD 100%);
            //     }
            //     75% {
            //         background: linear-gradient(90deg, #8FF6E2 0%, #938AFD 25%, #F243AC 50%, #F5CD80 75%, #8FF6E2 100%);
            //     }
            //     100% {
            //         background: linear-gradient(90deg, #F5CD80 0%, #8FF6E2 25%, #938AFD 50%, #F243AC 75%, #F5CD80 100%);
            //     }
            // }
            
            
            .messagesContainer {
                border-radius: 8px;
                height: calc(100vh - 260px);
                background: var(--secondary-surface, $BgGradient);
                padding: 2rem;
                overflow-y: scroll;
                    // make the scroll bar invisible
                    &::-webkit-scrollbar {
                        width: 0px;
                        background: transparent; /* make scrollbar transparent */
                    }
                .messageItemContainer {
                    margin-top: 8px;
                    overflow-wrap: break-word;
                    &.user {
                        background: var(--primary-surface, $BgBlue);
                        .avatar {
                            background-color: var(--secondary-surface, $DarkGrey);
                            border-radius: 50%;
                            color: $Grey;
                            font-size: 24px;
                            height: 36px;
                            width: 36px;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    &.ai {
                        background: var(--tertiary-surface, $ExtraDarkGrey);
                        .avatar {
                            height: 36px;
                            width: 36px;
                            background-color: var(--secondary-surface, $Black);
                            border-radius: 50%;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            font-size: 24px;
                        }
                        &.thinking {
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            background-color: transparent;
                            .messageContent {
                                max-height: 100px;
                                overflow: hidden;
                                div {
                                    position: relative;
                                    top: -50px;
                                    opacity: 0.7;
                                    animation-name: fadeInOut;
                                    animation-duration: 2s;  // Adjust this to control the speed of the fade-in and fade-out effect
                                    animation-iteration-count: infinite;  // This will make the animation loop indefinitely
                                }
                                @keyframes fadeInOut {
                                    0% {opacity: 0.4;}
                                    50% {opacity: 0.9;}
                                    100% {opacity: 0.4;}
                                }
                            }
                        }
                    }
                    border-radius: 8px;
                    padding: 12px 12px 12px 12px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    .messageContent {
                        margin-left: 12px;
                        .messageType {
                            font-size: $xSmall;
                            color: var(--secondary-foreground, $Grey);
                        }
                        .messageText {
                            font-size: $small;
                            color: var(--primary-foreground, $White);
                            p {
                                word-break: break-word;
                            }
                        }
                    }
                }
                .introContainer {
                    background-color: 'red' !important;
                    padding: 36px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    .playerOuterWrapper {
                        border-radius: 50%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 16px 16px;
                        height: 160px;
                        width: 160px;
                        .logoImg {
                            height: 128px;
                            width: auto;
                            border-radius: 50%;
                            margin: 0 12px;
                        }
                        .playerWrapper {
                            position: relative;
                            width: 150px;
                            height: 150px;
                            border-radius: 50%;
                            overflow: hidden;
                            .reactPlayer {
                                position: absolute;
                                top: -50%; /* these negative percentages effectively zoom the video */
                                left: -50%;
                                overflow: hidden;
                                border-radius: 50%;
                            }
                        }
                    }
                    .introTitle {
                        font-size: $large;
                        color: var(--primary-foreground, $White);
                        font-weight: 800;
                        margin-top: 24px;
                        text-align: center;
                    }
                    .introText {
                        font-size: $small;
                        color: var(--secondary-foreground, $Grey);
                        font-weight: 300;
                        text-align: center;
                    }
                    .link {
                        font-size: $small;
                        color: var(--primary-foreground, $White);
                        margin-top: 24px;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                            color: $Purple;
                        }
                    }
                    .tilesContainer {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        margin-top: 40px;
                        padding: 0 5%;
                        flex-wrap: wrap;
                        gap: 24px;
                        .tile {
                            margin-bottom: 12px;
                            min-width: 300px;
                            flex: 1;
                            min-height: 100px;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            padding: 12px 16px;
                            text-align: left;
                            background-color: var(--tertiary-surface, $BgBlue);
                            border-radius: 8px;
                            margin: 0 6px;
                            border: 1px solid var(--tertiary-surface, $BgBlue);
                            .tileTitle {
                                font-size: $xSmall;
                                color: var(--primary-foreground, $White);
                                font-weight: 400;
                            };
                            .tileText {
                                font-size: $xSmall;
                                color: var(--secondary-foreground, $Grey);
                                font-weight: 300;
                            };
                            .comingsoon {
                                font-size: $xxSmall;
                                color: $Purple;
                            }
                            &:hover {
                                border: 1px solid var(--primary-accent, $Purple);
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        .inputOuterContainer {
            .inputContainer {
                border: 1px solid var(--primary-accent, $BgPurple);
                background-color: var(--secondary-surface, $Black);
                border-radius: 8px;
                margin-top: 24px;
                display: flex;
                flex-direction: row;
                padding: 12px 12px;
                .input {
                    flex: 1;
                    background-color: transparent;
                    border: 0;
                    outline: none !important;
                    box-shadow: none !important;
                    font-size: $small;
                    transform: scale(0.8);
                    transform-origin: left;
                    color: var(--primary-foreground, $White);
                    font-weight: 400;
                    border-radius: 8px;
                    padding: 8px 12px;
                    &:focus {
                        outline: none !important;
                        box-shadow: none !important;
                    }
                }
                .customButton {
                    background: transparent;
                    border: 0;
                    border-radius: 0px 8px 8px 0px;
                    .iconText {
                        color: var(--primary-accent, $Purple);
                    }
                    &:hover {
                        background: var(--primary-accent, $Gradient);
                        cursor: pointer;
                        .iconText {
                            color: $White;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .chat {
        height: 100%;
        .chatContainer {
            height: 100%;
            .messagesOuterContainer {
                height: calc(100% - 120px);
                margin-top: 24px;
                .messagesContainer {
                    height: 100%;
                    padding: 8px;
                    overflow-y: scroll;
                    .messageItemContainer {
                        &.ai {
                            &.thinking {
                                .messageContent {
                                    div {
                                        top: 0px;
                                    }
                                }
                            }
                        }
                        .messageContent {
                            .messageType {
                                font-size: $xSmall;
                            }
                            .messageText {
                                font-size: $xSmall;
                            }
                        }
                    }
                    // make the scroll bar invisible
                    &::-webkit-scrollbar {
                        width: 0px;
                        background: transparent; /* make scrollbar transparent */
                    }
                    .introContainer {
                        justify-content: center;
                        .tilesContainer {
                            flex-direction: column;
                            .tile {
                                margin: 6px 0;
                            }
                            padding-bottom: 24px;
                        }
                    }
                }
            }
        }
    }
  }